import { z } from "zod";

import http from "@/services/http";

const marketingTextOptions = z
  .object({
    id: z.number(),
    marketing_code: z.string(),
    last_updated_by: z.string(),
    marketing_text: z.string(),
    created_at: z.string().datetime(),
    updated_at: z.string().datetime(),
    deleted_at: z.string().datetime().nullish(),
  })
  .passthrough();

const marketingCardOptions = z
  .object({
    alignment: z.string(),
    bg_color: z.string(),
    card_color: z.string(),
    created_at: z.string().datetime(),
    deleted_at: z.string().datetime().nullish(),
    id: z.number(),
    image_url: z.string(),
    last_updated_by: z.string(),
    link: z.string(),
    marketing_code: z.string(),
    target: z.boolean().nullable(),
    text_color: z.string(),
    updated_at: z.string().datetime(),
  })
  .passthrough();

const marqueeResponse = z
  .object({
    data: z.object({
      marketing_card_options: z.array(marketingCardOptions),
      marketing_text_options: z.array(marketingTextOptions),
    }),
  })
  .strict();

const marquee = z
  .object({
    marketing_card_options: marketingCardOptions,
    marketing_text_options: z.array(marketingTextOptions),
  })
  .strict();

export type MarqueeResponseResult = z.infer<typeof marqueeResponse>;

export type Marquee = z.infer<typeof marquee>;

export async function fetch() {
  const { data } = await http().get(
    `${window.env.MARKETING_MANAGEMENT_API}/marketing-info/current`
  );

  return marqueeResponse.parse(data);
}
