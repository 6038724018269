<script lang="ts">
import "@cisco-u/buttons/cu-buttons.js";

import { defineComponent } from "vue";

const sanitizeUrl = await import("@braintree/sanitize-url");

export default defineComponent({
  emits: ["addProduct", "navigateToPlans"],
  props: {
    discount: {
      type: Boolean,
      default: false,
    },
    discountPrice: {
      type: String,
      default: "",
    },
    price: {
      type: String,
      default: "",
    },
    duration: {
      type: String,
      default: "",
    },
    gui: {
      type: String,
      default: "",
    },
    isSingle: {
      type: Boolean,
      default: false,
    },
    name: {
      type: String,
      default: "",
    },
    clc_count: {
      type: Number,
      default: 0,
    },
    acronym: {
      type: String,
      default: "",
    },
    display_version: {
      type: String,
      default: "",
    },
    path_id: {
      type: Number,
      default: 0,
    },
    bundleId: {
      type: Number,
      default: 0,
    },
    practiceExam: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    formattedPrice() {
      return this.discount ? this.discountPrice : this.price;
    },
    fromPath() {
      return this.$route.name === "Path";
    },
    showVersion() {
      return (
        (this.isSingle && this.display_version != "0.0") || this.acronym != ""
      );
    },
  },
  methods: {
    createEvent(to: any, target: any) {
      return {
        href: sanitizeUrl.sanitizeUrl(to),
        target: target,
      };
    },
  },
});
</script>

<template>
  <div
    class="flex max-h-[23.75rem] min-h-[23.75rem] flex-col items-center rounded-[0.625rem] border border-[#C4C4C4] px-6 py-8"
  >
    <p class="-mx-4 -mt-[0.813rem] text-center text-14 font-normal">
      <slot></slot>
    </p>
    <div
      class="mt-4 min-h-[4.0625rem] text-center text-12 leading-[120%] text-black"
    >
      <span>{{ name }}</span>
      <span class="ml-1" v-if="showVersion">(</span>
      <span v-if="acronym != ''">{{ acronym }}</span>
      <span class="ml-1" v-if="display_version != '0.0'">{{
        display_version
      }}</span>
      <span v-if="showVersion">)</span>
    </div>
    <div
      v-if="formattedPrice && formattedPrice != 'NaN'"
      :class="[
        { 'text-blue-light': discount },
        'mt-6 flex justify-center text-36',
      ]"
    >
      <p
        :class="[
          { 'text-18 text-blue-light': discount },
          'mb-2.5 flex items-center pr-0.5 text-16 text-black',
        ]"
      >
        $
      </p>
      <span>
        {{ formattedPrice.split(".")[0] }}
      </span>
      <div class="pl-1 pt-1">
        <p class="text-12 leading-5">{{ formattedPrice.split(".")[1] }}</p>
        <p class="text-8">USD</p>
      </div>
    </div>
    <div
      v-if="discount && price && price != 'NaN'"
      class="discount-price mt-3.5 flex justify-center text-22 font-extralight tracking-wide text-black-lightest"
    >
      <p class="mb-2.5 flex items-center pr-0.5 text-16 text-black-lightest">
        $
      </p>
      <span>
        {{ price.split(".")[0] }}
      </span>
      <div class="pl-1">
        <p class="text-12">{{ price.split(".")[1] }}</p>
        <p class="text-8">USD</p>
      </div>
    </div>
    <div class="min-h-10" v-else></div>
    <div>
      <p class="text-center text-9">OR</p>
      <p class="text-center text-9">
        <span class="text-blue-light">{{ clc_count }}</span> Cisco Learning
        Credits (CLCs)
      </p>
    </div>
    <p
      class="mt-3.5 text-center text-9"
      v-if="duration && duration != '' && duration != 'undefined'"
    >
      Duration: {{ duration }}
    </p>
    <cu-buttons
      @click.prevent="$emit('addProduct', gui)"
      @keyup.enter.prevent="$emit('addProduct', gui)"
      class="mt-3.5 inline-block text-12"
      btnType="primary"
      value="Add to cart"
      width="135.5px"
      height="28.39px"
      tabindex="0"
    />
    <p v-if="!isSingle" :class="[{ 'mt-4': discount, 'mt-5': !discount }]">
      <router-link
        :to="{ name: 'Explore', query: { subscription: bundleId } }"
        custom
        v-slot="{ href }"
      >
        <a
          :href="href"
          @click.prevent="$emit('navigateToPlans', createEvent(href, '_blank'))"
          @keyup.space.prevent="
            $emit('navigateToPlans', createEvent(href, '_blank'))
          "
          @keyup.enter.prevent="
            $emit('navigateToPlans', createEvent(href, '_blank'))
          "
          tabindex="0"
          id="view-plans-access"
          data-cy="view-plans-link"
          class="border-b border-dotted text-10"
          >See included titles
        </a>
      </router-link>
    </p>
    <p
      v-else-if="isSingle && !fromPath && !practiceExam"
      :class="[{ 'mt-4': discount, 'mt-5': !discount }]"
    >
      <router-link
        :to="{ name: 'Path', params: { pathId: path_id } }"
        custom
        v-slot="{ href }"
      >
        <a
          :href="href"
          @click.prevent="$emit('navigateToPlans', createEvent(href, '_self'))"
          @keyup.space.prevent="
            $emit('navigateToPlans', createEvent(href, '_self'))
          "
          @keyup.enter.prevent="
            $emit('navigateToPlans', createEvent(href, '_self'))
          "
          tabindex="0"
          id="view-plans-access"
          data-cy="view-plans-link"
          class="border-b border-dotted text-10"
          >Learn more
        </a>
      </router-link>
    </p>
    <p
      v-else-if="isSingle && !fromPath && practiceExam"
      :class="[{ 'mt-4': discount, 'mt-5': !discount }]"
    >
      <router-link
        :to="{ name: 'practice-exam', params: { practiceExamId: path_id } }"
        custom
        v-slot="{ href }"
      >
        <a
          :href="href"
          @click.prevent="$emit('navigateToPlans', createEvent(href, '_self'))"
          @keyup.space.prevent="
            $emit('navigateToPlans', createEvent(href, '_self'))
          "
          @keyup.enter.prevent="
            $emit('navigateToPlans', createEvent(href, '_self'))
          "
          tabindex="0"
          id="view-plans-access"
          data-cy="view-plans-link"
          class="border-b border-dotted text-10"
          >Learn more
        </a>
      </router-link>
    </p>
  </div>
</template>

<style scoped>
.discount-price::after {
  content: "________";
  width: 6.25rem;
  text-decoration: line-through;
  position: absolute;
  color: white;
  text-decoration-color: #606162;
  text-decoration-thickness: 1px;
}
</style>
