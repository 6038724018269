<script lang="ts">
import "@cisco-u/reicon-lock/cu-reicon-lock.js";

import { debounce } from "lodash-es";
import { defineComponent } from "vue";
import { mapActions, mapGetters } from "vuex";

import AnchorTargetBlankCallback from "@/components/AnchorTargetBlankCallback.vue";
import theCard from "@/components/contentBits/theCard.vue";
import isUnauth from "@/utils/isUnauth";
import telemetry from "@/utils/telemetry";

export default defineComponent({
  components: {
    theCard,
    AnchorTargetBlankCallback,
  },
  props: {
    result: {
      type: Object,
      default: () => ({}),
    },
    index: {
      type: Number,
      default: 0,
    },
    status: {
      type: Object,
      default: null,
    },
    cardView: {
      type: String,
      default: "Grid",
    },
  },
  data() {
    return {
      click: false,
    };
  },
  computed: {
    ...mapGetters({
      inMyList: "user/inMyList",
      upcomingClasses: "instance/upcomingClasses",
      freeId: "content/freeId",
    }),
    freeContent(): boolean {
      return this.result?.content_bundle?.includes(this.freeId);
    },
    showPremium(): boolean {
      return !this.status?.userEntitlements?.includes(this.result.guid);
    },
    resultLink() {
      return "/instructor-led/" + this.result.id;
    },

    subLink() {
      return window.env.LEARNING_LOCATOR_URL + this.result.product_id;
    },
    resourceUrl() {
      return this.result.catalog_data
        ? this.result.catalog_data.url
        : this.result.url;
    },
  },
  methods: {
    isUnauth,
    ...mapActions({
      fetchListByType: "user/fetchListByType",
      removeContentFromMyList: "user/removeContentFromMyList",
      addContentToMyList: "user/addContentToMyList",
    }),
    hideClicked() {
      this.click = false;
    },
    whereTo() {
      if (this.showPremium) {
        this.click = true;
      } else {
        this.click = false;
        this.sendTelemetry();
      }
    },

    bookmarkMe(content: any) {
      this.inMyList(content)
        ? this.removeContentFromMyList({
            routeName: this.$route.name,
            result: content,
          })
        : this.addContentToMyList({
            routeName: this.$route.name,
            result: content,
          });
    },
    debouncedBookmarkMe: debounce(function (content: any) {
      // @ts-ignore
      this.bookmarkMe(content);
    }, 500),
    async sendTelemetry() {
      let telemetryResponse = telemetry.external(
        {
          action: "launch-contentType",
          url: this.click ? this.subLink : this.resultLink,
          title: this.click ? "Subscribe to Cisco U." : this.result.title,
          id: this.result.id,
          page: this.$route.fullPath,
          type: this.click ? "subscription offer" : this.result.type,
          relevance: this.result.relevance,
          sortOrder: this.index,
        },
        this.isUnauth()
      );
      telemetryResponse
        .then(() => {
          if (this.showPremium) {
            window.open(this.subLink, "_blank", "noopener");
          } else {
            this.$router.push(this.resultLink);
          }
        })
        .catch(() => {
          // do nothing
        });
    },
  },
});
</script>

<template>
  <div
    v-show="result"
    data-cy="instructor-led-result"
    :class="[
      'relative z-10',
      {
        'w-[19rem]': cardView === 'Grid',
        'w-full': cardView != 'Grid',
      },
    ]"
  >
    <the-card
      :is-unauth="isUnauth()"
      :card-view="cardView"
      :result="result"
      :status="status"
      :cy="'instructor-led-result-link'"
      @navigate="whereTo"
      @bookmark="debouncedBookmarkMe(result)"
    />
    <div
      :class="[
        'mt-0',
        {
          hidden: !showPremium || !click,
          'mask_it absolute left-0 top-0 mt-0 h-full w-full rounded-[0.625rem] bg-[#000000]/80':
            showPremium && click,
        },
      ]"
      v-if="click"
    >
      <cu-reicon-lock
        type="filled-keyhole"
        dark="true"
        size="sm"
        class="mt-8 flex justify-center"
      />
      <div
        class="mx-10 text-center text-13 text-white-lightest"
        v-click-outside="{
          handler: hideClicked,
          events: ['dblclick', 'click'],
          capture: true,
        }"
      >
        To enroll in this course, use<br />

        <AnchorTargetBlankCallback
          :url="subLink"
          class="underline"
          @clicked="sendTelemetry"
          >Cisco Learning Locator</AnchorTargetBlankCallback
        >
        to find it for purchase.
      </div>
    </div>
  </div>
</template>
