import type { RouterOptions } from "vue-router";

const redirects: RouterOptions["routes"] = [
  {
    path: "/challenge/:challengeId",
    redirect: (to) => {
      return {
        name: "Challenge",
        params: { challengeId: to.params.challengeId },
      };
    },
  },
  {
    path: "/explore/course",
    redirect: { name: "explore-courses" },
  },
  {
    path: "/explore/events-and-webinars",
    redirect: { name: "explore-events" },
  },
  {
    path: "/explore/instructor-led",
    redirect: { name: "explore-instructorled" },
  },
  {
    path: "/explore/path",
    redirect: { name: "explore-paths" },
  },
  {
    path: "/explore/podcasts",
    redirect: { name: "explore-podcasts" },
  },
  {
    path: "/explore/practice-exam",
    redirect: { name: "explore-exams" },
  },
  {
    path: "/explore/tutorials",
    redirect: { name: "explore-tutorials" },
  },
  {
    path: "/explore/video-series",
    redirect: { name: "explore-videos" },
  },
  {
    path: "/login",
    name: "login",
    redirect: "/for-you",
  },
  {
    path: "/path/:pathId",
    redirect: (to) => {
      return { name: "Path", params: { pathId: to.params.pathId } };
    },
  },
  {
    path: "/practice-exam/:practiceExamId",
    redirect: (to) => {
      return {
        name: "practice-exam",
        params: { practiceExamId: to.params.practiceExamId },
      };
    },
  },
  {
    path: "/tutorial/:tutorialId",
    redirect: (to) => {
      return { name: "Tutorial", params: { tutorialId: to.params.tutorialId } };
    },
  },
  {
    path: "/:pathMatch(.*)*",
    name: "error",
    redirect: "/for-you",
  },
];

export default redirects;
