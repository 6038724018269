// common layout settings for pages
// to be used in route meta fields

export const showHeader = {
  pageHeader: true,
};

export const hideHeader = {
  pageHeader: false,
};

export const hideMarquee = {
  hideMarquee: true,
};

export const scrollShowProfile = {
  scrollShowProfile: true,
};

export const ContentPage = {
  ...hideMarquee,
  ...scrollShowProfile,
};

export const SubPage = {
  ...showHeader,
  ...ContentPage,
};

export const Unauth = {
  unauth: true,
};

export const AuthUnauthContentPage = {
  ...ContentPage,
  ...Unauth,
};

export const AuthUnauthSubPage = {
  ...SubPage,
  ...Unauth,
};
