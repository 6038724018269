<script lang="ts">
import { defineComponent } from "vue";
import { LocationQueryValue } from "vue-router";
type QueryString = string | LocationQueryValue | LocationQueryValue[];
import "@cisco-u/icons/icons/cu-icon-close-x.js";
import "@cisco-u/icons/icons/cu-icon-cart-thick.js";
import "@cisco-u/icons/icons/cu-icon-search.js";
import "@cisco-u/icons/icons/cu-icon-menu.js";
import "@cisco-u/logo/cu-logo.js";
import "@cisco-u/text-badge/cu-text-badge.js";

import AiPromptBtn from "@/components/AiPromptBtn.vue";
import AnchorTargetBlank from "@/components/AnchorTargetBlank.vue";
import AnchorTargetSelf from "@/components/AnchorTargetSelf.vue";
import nav from "@/layout/Header/nav.json";
import { EventBus } from "@/store/eventBus";

export default defineComponent({
  components: {
    AnchorTargetBlank,
    AnchorTargetSelf,
    AiPromptBtn,
  },
  props: {
    dark: {
      type: Boolean,
      default: false,
    },
    isAdminRole: {
      type: Boolean,
      default: false,
    },
    isCustomerAdminRole: {
      type: Boolean,
      default: false,
    },
    isContentAdminRole: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showSearchDrop: false,
      showSearchInput: false,
      query: "" as QueryString,
      showNavigation: false,
      cartItems: 0,
      adminUrl: window.env.CISCO_ADMIN_URL,
      customerAdminUrl: window.env.CUSTOMER_ADMIN_URL,
      productSSOTUrl: window.env.PRODUCT_SSOT_URL,
    };
  },
  mounted() {
    EventBus.on("updateQty", (qty: number) => {
      this.cartItems = qty;
    });
  },
  computed: {
    theNav() {
      let navigation = nav;
      if (window.env.ENABLE_TOPICS_DISCOVERY) {
        // remove nav with title === "Explore"
        navigation = navigation.filter(
          (navItem) => navItem.title !== "Explore"
        );
      } else {
        // remove nav with title === "Learn"
        navigation = navigation.filter((navItem) => navItem.title !== "Learn");
      }
      return navigation;
    },
    showGenAi() {
      return window.env.SHOW_GEN_AI;
    },
  },
  watch: {
    $route() {
      this.queryParamSetup();
      if (this.$route && this.$route.name != "Search") {
        this.query = null;
      }
    },
    query() {
      if (this.query) {
        this.search(false);
      }
    },
  },
  methods: {
    toggleSearch() {
      this.showSearchInput = !this.showSearchInput;
      this.$nextTick(() => {
        if (this.showSearchInput && this.$refs?.searchHeader) {
          (this.$refs.searchHeader as HTMLElement).focus();
        }
      });
    },

    queryParamSetup() {
      if (this.$route.name == "Search" && this.$route?.query?.query) {
        this.query = this.$route.query.query;
      }
    },
    search(redirect: any) {
      this.showSearchDrop = true;

      if (
        ["Search", "search-all", "search-type"].includes(
          this.$route.name as string
        ) ||
        redirect
      ) {
        this.$router.push({ name: "search-all", query: { query: this.query } });
        this.showSearchDrop = false;
      }
    },
    goToCart() {
      if (window.location.pathname == "/purchase") {
        EventBus.emit("showCart");
      } else {
        this.$router.push({ name: "purchase" });
      }
    },
  },
});
</script>

<template>
  <div
    data-cy="page-mobile-header"
    :class="[
      'fixed left-0 right-0 top-0 z-[1000] w-full lg:hidden',
      {
        'bg-black-dark': dark,
        'bg-white-lightest': !dark,
      },
    ]"
  >
    <router-link
      v-if="true"
      to="/search/tutorial?query=AI%20chatgpt"
      class="block bg-blue-dark px-6 py-4 text-center text-16 leading-[1.2] -tracking-[0.0275rem] text-white-lightest hover:underline"
      active-class="underline"
      ><strong class="font-thicker"
        >Free AI training and tutorials from Cisco U.</strong
      >
      From entry to expert, gain real-world skills to succeed in
      AI.</router-link
    >

    <div
      :class="[
        'flex w-full items-center justify-between px-4 py-2 lg:hidden',
        {
          'text-white-lightest': dark,
          'text-black-dark dark:text-white-dark': !dark,
        },
      ]"
    >
      <button
        data-cy="mobile-hamburger"
        @click.prevent="showNavigation = !showNavigation"
        @keyup.tab="showNavigation = !showNavigation"
        @keyup.space="showNavigation = !showNavigation"
      >
        <span class="sr-only" v-if="showNavigation == false">
          Show Navigation
        </span>
        <cu-icon-menu v-if="showNavigation == false" size="l" />

        <span class="sr-only" v-if="showNavigation == true">
          Hide Navigation
        </span>
        <cu-icon-close-x v-if="showNavigation == true" size="l" />
      </button>

      <router-link tabindex="0" data-cy="mobile-logo" to="/" class="flex">
        <cu-logo label="Cisco" type="black-small" />
      </router-link>
      <div>
        <button
          data-cy="mobile-search-header-icon"
          @click="toggleSearch"
          @keyup.tab="toggleSearch"
          @keyup.space="toggleSearch"
          class="inline-block"
        >
          <span class="sr-only">Search</span>
          <cu-icon-search
            class="sr-hidden mr-2 border-none text-white-dark"
            size="l"
          />
        </button>
        <ai-prompt-btn v-if="showGenAi" class="mr-2" />
        <button
          data-cy="mobile-user-cart-icon"
          @click="goToCart()"
          class="inline-block"
        >
          <cu-text-badge
            v-if="cartItems > 0"
            class="relative left-[0.1rem] top-[0.6rem] z-10"
            :text="cartItems"
            bgcolor="red-secondary"
            color="white"
            data-cy="user-cart-item-badge"
            medium="true"
          />
          <cu-icon-cart-thick
            class="sr-hidden border-none text-white-dark"
            size="l"
          />
        </button>
      </div>
    </div>
    <div
      v-if="showSearchInput"
      :class="[
        'pb-[0.0625rem]',
        {
          'bg-black-dark': dark,
          'bg-white-lightest': !dark,
        },
      ]"
    >
      <label
        for="searchHeaderMobile"
        class="mx-auto block w-4/5 border-0 lg:hidden"
      >
        <span class="sr-only">Search</span>
        <input
          ref="searchHeader"
          id="searchHeaderMobile"
          type="text"
          :class="[
            'mt-0 w-full border-none p-4 font-thin',
            {
              'bg-black text-white placeholder:text-white': dark,
              'dark:bg-black-lightest dark:text-white dark:placeholder:text-white':
                !dark,
            },
          ]"
          placeholder="What would you like to learn?"
          @keyup.prevent="search(false)"
          @keyup.enter="search(true)"
          v-model="query"
        />
      </label>
    </div>

    <div
      v-if="showNavigation == true"
      :class="[
        'z-100 fixed left-0 right-0 h-screen w-full overflow-y-scroll',
        {
          'bg-black-dark text-white-lightest': dark,
          'bg-white-lightest text-black-dark': !dark,
          'top-30 pb-1': showSearchInput,
          'top-22': !showSearchInput,
        },
      ]"
    >
      <div class="container mx-auto lg:px-4">
        <ul class="pt-4">
          <li v-for="navItem in theNav" :key="navItem.title" class="my-8">
            <router-link
              tabindex="0"
              :data-cy="`mobile-nav${navItem.route}`"
              active-class="underline"
              class="block whitespace-nowrap text-2xl hover:text-black-light hover:underline focus:underline focus:ring-2"
              :to="{ name: navItem.route }"
              @click="showNavigation = false"
            >
              {{ navItem.title }}
            </router-link>
            <ul v-if="navItem.subPages" class="pl-4">
              <li
                v-for="subPage in navItem.subPages"
                :key="`subpage-${subPage.route}`"
                class="my-8"
              >
                <router-link
                  tabindex="0"
                  :data-cy="`mobile-nav${subPage.route}`"
                  active-class="underline"
                  class="block whitespace-nowrap text-2xl hover:text-black-light hover:underline focus:underline focus:ring-2"
                  :to="{
                    name: subPage.route,
                  }"
                  @click="showNavigation = false"
                >
                  {{ subPage.title }}
                </router-link>
              </li>
            </ul>
          </li>
        </ul>
        <anchor-target-self
          v-if="isCustomerAdminRole"
          data-cy="mobile-customer-admin"
          :url="customerAdminUrl"
          class="my-8 block whitespace-nowrap text-2xl hover:text-black-light hover:underline focus:underline focus:ring-2"
        >
          Customer Admin
        </anchor-target-self>
        <ul v-if="isCustomerAdminRole" class="pl-4">
          <li class="my-8">
            <anchor-target-self
              data-cy="mobile-customer-subscriptions"
              :url="customerAdminUrl + '/subscriptions'"
              class="block whitespace-nowrap text-2xl hover:text-black-light hover:underline focus:underline focus:ring-2"
            >
              Orders
            </anchor-target-self>
          </li>
          <li class="my-8">
            <anchor-target-self
              data-cy="mobile-customer-learners"
              :url="customerAdminUrl + '/learners'"
              class="block whitespace-nowrap text-2xl hover:text-black-light hover:underline focus:underline focus:ring-2"
            >
              Learners
            </anchor-target-self>
          </li>
        </ul>
        <anchor-target-blank
          data-cy="mobile-content-admin"
          :url="productSSOTUrl + '/catalogmanagement'"
          class="my-8 block whitespace-nowrap text-2xl hover:text-black-light hover:underline focus:underline focus:ring-2"
        >
          Catalog Management
        </anchor-target-blank>
        <anchor-target-blank
          url="https://servicedesk.certsite.net/jira/servicedesk/customer/portal/3"
          class="my-8 block whitespace-nowrap text-2xl hover:text-black-light hover:underline focus:underline focus:ring-2"
        >
          Help & Support
        </anchor-target-blank>
        <anchor-target-blank
          v-if="isAdminRole"
          data-cy="admin"
          :url="adminUrl"
          class="block whitespace-nowrap text-2xl hover:text-black-light hover:underline focus:underline focus:ring-2"
        >
          Admin
        </anchor-target-blank>
        <anchor-target-self
          class="block whitespace-nowrap text-2xl hover:text-black-light hover:underline focus:underline focus:ring-2 xs:mb-16 xs:mt-8"
          data-cy="logout"
          url="/sso-logout"
          >Logout</anchor-target-self
        >
      </div>
    </div>
  </div>
</template>

<style scoped>
/* for cisco-u components */
[type="search"] {
  background: transparent;
  border-width: 0;
  padding: 0;
}
</style>
