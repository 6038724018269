import { startCase } from "lodash-es";
import qs from "qs";
import type { parseQuery, RouterOptions } from "vue-router";
import {
  createRouter,
  createWebHashHistory,
  createWebHistory,
} from "vue-router";

import {
  AuthUnauthContentPage,
  AuthUnauthSubPage,
  ContentPage,
  hideHeader,
  hideMarquee,
  showHeader,
  SubPage,
  Unauth,
} from "@/router/pageMetaSettings";
import redirects from "@/router/redirects";
import isUnauth from "@/utils/isUnauth";
import * as redirectCookie from "@/utils/redirectUrlCookie";
import telemetry from "@/utils/telemetry";
import forYou from "@/views/ForYou/forYou.vue";
import unauthLanding from "@/views/LandingPage/unauthLanding.vue";

const routes: RouterOptions["routes"] = [
  {
    path: "/",
    name: "home",
    component: unauthLanding,
    meta: {
      ...hideHeader,
      ...hideMarquee,
      ...Unauth,
      title: "Cisco U.",
    },
  },
  {
    path: "/ai-window",
    name: "ai-window",
    component: () => import("../views/AiWindow/IndexPage.vue"),
    meta: {
      ...ContentPage,
      title: "AiWindow",
    },
  },
  {
    path: "/certifications",
    name: "certifications",
    component: () => import("../views/Certifications/IndexPage.vue"),
    meta: {
      ...AuthUnauthContentPage,
      title: "Certifications",
    },
  },
  {
    path: "/certifications/:certificationId/:name?",
    name: "certification",
    component: () => {
      if (isUnauth()) {
        return import(
          "../views/Certifications/UnauthCertificationLandingPage.vue"
        );
      }
      return import("../views/Certifications/CertificationLandingPage.vue");
    },
    meta: {
      ...AuthUnauthContentPage,
      title: "Certification",
    },
  },
  {
    path: "/challenges/:challengeId",
    name: "Challenge",
    component: () => import("../views/ChallengePage/IndexPage.vue"),
    meta: {
      ...AuthUnauthContentPage,
      title: "Challenge",
    },
  },
  {
    path: "/communities",
    name: "community-landing-page",
    component: () => import("../views/CommunityLanding/IndexPage.vue"),
    meta: {
      ...AuthUnauthSubPage,
      title: "Communities",
    },
  },
  {
    path: "/course/:courseId",
    name: "Course",
    component: () => import("../views/CoursePage/IndexPage.vue"),
    meta: {
      ...ContentPage,
      title: "Course",
      unauth: true,
    },
  },
  {
    path: "/exams/:practiceExamId",
    name: "practice-exam",
    component: () => import("../views/PracticeExamPage/IndexPage.vue"),
    meta: {
      ...AuthUnauthContentPage,
      title: "Practice Exam Overview Page",
    },
  },
  {
    path: "/explore",
    name: "Explore",
    props: (route: any) => ({ queryParameters: route.query }),
    component: () => import("../views/Explore/IndexPage.vue"),
    redirect: { name: "all" },
    children: [
      {
        path: "",
        name: "all",
        component: () => import("../views/Search/ExploreAll.vue"),
        meta: {
          ...showHeader,
          title: "Explore - All",
          type: "all",
          ...Unauth,
        },
      },
      {
        path: ":type",
        name: "explore-type",
        component: () => import("../views/Search/ViewOneType.vue"),
        meta: {
          ...AuthUnauthSubPage,
          title: "Explore - ",
        },
      },
      {
        path: "/courses",
        name: "explore-courses",
        component: () => import("../views/Search/ViewOneType.vue"),
        meta: {
          ...AuthUnauthSubPage,
          title: "Explore - Learning courses ",
          type: "courses",
        },
      },
      {
        path: "/challenges",
        name: "explore-challenges",
        component: () => import("../views/Search/ViewOneType.vue"),
        meta: {
          ...AuthUnauthSubPage,
          title: "Explore - Challenges",
          type: "challenges",
        },
      },
      {
        path: "/events",
        name: "explore-events",
        component: () => import("../views/Search/ViewOneType.vue"),
        meta: {
          ...AuthUnauthSubPage,
          title: "Explore - Events and Webinars",
          type: "events-and-webinars",
        },
      },
      {
        path: "/exams",
        name: "explore-exams",
        component: () => import("../views/Search/ViewOneType.vue"),
        meta: {
          ...AuthUnauthSubPage,
          title: "Explore - Practice Exams ",
          type: "practice-exams",
        },
      },
      {
        path: "/instructorled",
        name: "explore-instructorled",
        component: () => import("../views/Search/ViewOneType.vue"),
        meta: {
          ...AuthUnauthSubPage,
          title: "Explore - Instructor Led ",
          type: "instructor-led",
        },
      },
      {
        path: "/paths",
        name: "explore-paths",
        component: () => import("../views/Search/ViewOneType.vue"),
        meta: {
          ...AuthUnauthSubPage,
          title: "Explore - Learning Paths ",
          type: "learning-paths",
        },
      },
      {
        path: "/podcasts",
        name: "explore-podcasts",
        component: () => import("../views/Search/ViewOneType.vue"),
        meta: {
          ...AuthUnauthSubPage,
          title: "Explore - Podcasts",
          type: "podcasts",
        },
      },
      {
        path: "/tutorials",
        name: "explore-tutorials",
        component: () => import("../views/Search/ViewOneType.vue"),
        meta: {
          ...AuthUnauthSubPage,
          title: "Explore - Tutorials ",
          type: "tutorials",
        },
      },
      {
        path: "/videos",
        name: "explore-videos",
        component: () => import("../views/Search/ViewOneType.vue"),
        meta: {
          ...AuthUnauthSubPage,
          title: "Explore - Videos",
          type: "video-series",
        },
      },
    ],
  },
  {
    path: "/for-you",
    name: "ForYou",
    component: forYou,
    meta: {
      ...showHeader,
      title: "For You",
    },
  },
  {
    path: "/instructor-led/:iltId?",
    name: "ILT",
    component: () => import("../views/ILTPage/IndexPage.vue"),
    meta: {
      ...AuthUnauthContentPage,
      title: "Instructor-led Training",
    },
  },
  {
    path: "/learn",
    name: "learn",
    component: () => import("../views/Learn/LearnPage.vue"),
    meta: {
      ...AuthUnauthContentPage,
      title: "learn",
    },
  },
  {
    path: "/learn/:topic",
    name: "learn-topic",
    props: true,
    component: () => import("../views/Learn/TopicPage.vue"),
    meta: {
      ...AuthUnauthSubPage,
      title: "learn about",
    },
  },
  {
    path: "/my-learning",
    name: "MyLearning",
    component: () => import("../views/MyLearning/IndexPage.vue"),
    children: [
      {
        path: "cdl-user",
        name: "cdl-user",
        component: () => import("../views/MyLearning/cdlUserCompletes.vue"),
        meta: {
          title: "My Dashboard - My CdlUserCompletes",
        },
      },
      {
        path: "community",
        name: "community",
        component: () => import("../views/MyLearning/community-page.vue"),
        meta: {
          title: "My Dashboard - My Community",
        },
      },
      {
        path: "",
        name: "dashboard",
        component: () => import("../views/MyLearning/myDashboard.vue"),
        meta: {
          title: "My Dashboard",
        },
      },
      {
        path: "discussion",
        name: "discussion",
        component: () => import("../views/MyLearning/community-page.vue"),
        meta: {
          title: "My Dashboard - My Community",
        },
      },
      {
        path: "my-account",
        name: "my-account",
        component: () => import("../views/MyLearning/myPurchases.vue"),
        meta: {
          ...hideHeader,
          ...hideMarquee,
          showProfileHeader: true,
          title: "My Dashboard - My Account",
        },
      },
      {
        path: "my-list",
        name: "my-list",
        component: () => import("../views/MyLearning/myLibrary.vue"),
        meta: {
          title: "My Dashboard - My Learning",
        },
      },
    ],
  },
  {
    path: "/my-practice-exam-score-report/:examId?",
    name: "my-practice-exam-score-report",
    component: () => import("../views/MyPracticeExamScoreReport/IndexPage.vue"),
    meta: {
      ...ContentPage,
      title: "My Practice Exam Score Report",
    },
  },
  {
    path: "/my-score-report/:pathId?",
    name: "my-score-report",
    component: () => import("../views/MyScoreReport/IndexPage.vue"),
    meta: {
      ...ContentPage,
      title: "My Score Reports",
    },
  },
  {
    path: "/my-score-report/complete-certificate",
    name: "complete-certificate",
    component: () => import("../views/MyScoreReport/completeCertificate.vue"),
    meta: {
      ...ContentPage,
      title: "Completion Certificate",
    },
  },
  {
    path: "/paths/:pathId",
    name: "Path",
    component: () => import("../views/LearningPathPage/IndexPage.vue"),
    meta: {
      ...AuthUnauthContentPage,
      title: "Path",
    },
  },
  {
    path: "/plans",
    name: "subscription",
    component: () => import("../views/Subscription/SubscriptionPlan.vue"),
    meta: {
      ...AuthUnauthSubPage,
      title: "Plans",
    },
  },
  {
    path: "/podcast/:podcastId?",
    name: "PodcastDetails",
    component: () => import("../views/PodcastLanding/PodcastLandingPage.vue"),
    meta: {
      ...AuthUnauthSubPage,
      title: "Podcast",
    },
  },
  {
    path: "/purchase",
    name: "purchase",
    component: () => import("../views/Subscription/buySubscription.vue"),
    meta: {
      ...SubPage,
      title: "Purchase",
    },
  },
  {
    path: "/purchase-paths",
    name: "purchase-paths",
    component: () => import("../views/Subscription/learningPaths.vue"),
    meta: {
      ...AuthUnauthSubPage,
      title: "Paths",
    },
  },
  {
    path: "/search",
    name: "Search",
    component: () => import("../views/Search/IndexPage.vue"),
    children: [
      {
        path: "",
        name: "search-all",
        props: true,
        component: () => import("../views/Search/ViewAllTypes.vue"),
        meta: {
          ...AuthUnauthSubPage,
          title: "Search",
        },
      },
      {
        path: ":type",
        name: "search-type",
        props: true,
        component: () => import("../views/Search/ViewOneType.vue"),
        meta: {
          ...AuthUnauthSubPage,
          title: "Search ",
        },
      },
    ],
  },
  {
    path: "/tutorials/:tutorialId",
    name: "Tutorial",
    component: () => import("../views/TutorialPage/IndexPage.vue"),
    meta: {
      ...AuthUnauthContentPage,
      title: "Tutorial",
    },
  },
  {
    path: "/under-construction",
    name: "under-construction",
    component: () => import("../views/FallbackPage.vue"),
    meta: {
      ...hideHeader,
      ...hideMarquee,
      showProfileHeader: false,
      title: "Under Construction",
      ...Unauth,
    },
  },
  {
    path: "/video-series/:videoSerieId?/:videoId?",
    name: "VideoSeries",
    component: () => import("../views/VideoSeriesPage/VideoSerie.vue"),
    meta: {
      ...ContentPage,
      title: "Video Series",
    },
  },

  ...redirects,
];

let history;

if (import.meta.env.TEST) {
  history = createWebHashHistory();
}
const router = createRouter({
  sensitive: true,
  history: history ?? createWebHistory(import.meta.env.BASE_URL),
  routes,
  parseQuery: qs.parse as typeof parseQuery,
  stringifyQuery(query: any): string {
    return qs.stringify(query, { arrayFormat: "comma", encode: false });
  },
  scrollBehavior(to, from, savedPosition) {
    if (to.name == from.name && to.name == "certifications") {
      const x = (document.querySelector("#result") as HTMLElement)?.offsetTop;
      return { left: 0, top: x, behavior: "smooth" };
    }
    if (to.hash == "#resubscribe") {
      const x = (document.querySelector("#resubscribe") as HTMLElement)
        ?.offsetTop;
      return { left: 0, top: x, behavior: "smooth" };
    }
    return { left: 0, top: 0 };
  },
});

router.beforeEach((to: any, _: any, next: any) => {
  if (to) {
    //Setup the page title
    let pageTitle: string = "Cisco U.";
    if (to?.meta?.title) {
      pageTitle = pageTitle + " | " + startCase(to.meta.title as string);
    }
    // alter page title
    document.title = pageTitle;

    // for search track
    telemetry.browseContent(
      pageTitle,
      to.fullPath,
      to.name,
      "Sort",
      isUnauth()
    );

    // for any page view, track
    telemetry.view_page("view-page", to.fullPath, pageTitle, isUnauth());

    if (to?.query?.returnTo) {
      const returnTo = to.query.returnTo;
      // check returnTo for query parameters
      if (returnTo.includes("?")) {
        const [path, query] = returnTo.split("?");
        next({
          path: path,
          query: qs.parse(query),
        });
      } else {
        next({
          path: to.query.returnTo,
        });
      }
    } else {
      next();
    }
  }
});

// redirect code test start
router.beforeResolve((to: any, from: any, next: any) => {
  if (!isUnauth()) {
    const redirectUrl = redirectCookie.getRedirectUrlCookie();
    if (redirectUrl) {
      redirectCookie.deleteRedirectUrlCookie();
      next(redirectUrl);
    } else {
      next();
    }
  } else {
    next();
  }
});
// redirect code test end
export { routes };
export default router;
