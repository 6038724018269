<script lang="ts">
import "@cisco-u/icons/icons/cu-icon-check.js";

import { defineComponent } from "vue";

import { EventBus } from "@/store/eventBus";

export default defineComponent({
  data() {
    return {
      bannerText: "",
      showMessage: false,
      bannerType: "SUCCESS",
      bannerClass: "bg-greenAlt",
    };
  },
  mounted() {
    EventBus.on("showBanner", (bannerData: any) => {
      this.bannerText = bannerData.message;
      this.bannerType = bannerData.type;
      this.bannerClass =
        this.bannerType == "SUCCESS" ? "bg-greenAlt" : "bg-[#fff7e7]";
      this.showMessage = true;
      setTimeout(() => {
        this.showMessage = false;
      }, 2500);
    });
  },
});
</script>

<template>
  <div
    :class="[
      `message fixed z-[998] mt-48 flex min-h-10 w-full items-center justify-center p-8 text-20 ${bannerClass}`,
      {
        show: showMessage,
      },
    ]"
  >
    <cu-icon-check
      v-if="bannerType == 'SUCCESS'"
      class="rounded-full bg-white-lightest p-1"
      size="l"
    ></cu-icon-check>
    <span
      v-else
      class="w-[1.65rem] rounded-full bg-[#e2231a] p-1 text-center text-12 font-light text-white-lightest"
      >X</span
    >
    <span
      :class="['ml-2', { 'text-white-lightest': bannerType == 'SUCCESS' }]"
      >{{ bannerText }}</span
    >
  </div>
</template>

<style scoped>
.message {
  visibility: hidden;
  opacity: 0;
  transition:
    opacity 450ms ease-in,
    visibility 450ms ease-in 0ms;
}

.message.show {
  visibility: visible;
  opacity: 1;
  transition:
    opacity 250ms ease-in,
    visibility 250ms ease-in 0ms;
}
</style>
