import dateFormats from "@/utils/dateFormats";
import getDuration from "@/utils/getDuration";

interface BuildIcon {
  type: string;
  text: string;
}

function singlePluralSimple(count: number, word: string): string {
  const expression = `${count} ${word}`;
  if (count > 1) {
    return `${expression}s`;
  }
  return `${expression}`;
}

function IconDuration(content: any): BuildIcon | undefined {
  if (getDuration(content) != "") {
    return {
      type: "time",
      text: getDuration(content),
    };
  }
  return undefined;
}

export function IconVideos(content: any): BuildIcon | undefined {
  if (content?.content_count > 0) {
    return {
      type: "video-filled",
      text: singlePluralSimple(content.content_count, "Video"),
    };
  }
  return undefined;
}

function IconDifficultyType(content: any): BuildIcon | undefined {
  if (content?.difficulty_type) {
    let iconType: string = "";
    switch (content.difficulty_type) {
      case "Beginner":
        iconType = "novice";
        break;
      case "Intermediate":
        iconType = "associate";
        break;
      case "Advanced":
        iconType = "specialist";
        break;
      default:
        iconType = "expert";
    }
    return {
      type: iconType,
      text: content.difficulty_type,
    };
  }
  return undefined;
}

function IconCourses(content: any): BuildIcon | undefined {
  if (content?.content_count > 0) {
    return {
      type: "article",
      text: singlePluralSimple(content.content_count, "Course"),
    };
  }
  return undefined;
}

function IconCreds(content: any): BuildIcon | undefined {
  if (content?.number_of_credits > 0 && !content?.is_partial) {
    return {
      type: "ce-credits",
      text: singlePluralSimple(content.number_of_credits, "Credit"),
    };
  }
  return undefined;
}
function IconAssessments(content: any): BuildIcon | undefined {
  if (content?.number_of_assessments > 0) {
    return {
      type: "assessment",
      text: singlePluralSimple(content.number_of_assessments, "assessment"),
    };
  }
  return undefined;
}

function IconVersion(content: any): BuildIcon | undefined {
  if (content?.display_version && content.display_version != "0.0") {
    return {
      type: "all",
      text: "v" + content.display_version,
    };
  }
  return undefined;
}

function IconLabs(content: any): BuildIcon | undefined {
  if (content?.number_of_labs > 0) {
    return {
      type: "lab",
      text: singlePluralSimple(content.number_of_labs, "Lab"),
    };
  }
  return undefined;
}

function IconCalendar(content: any): BuildIcon | undefined {
  if (content?.event_date) {
    return {
      type: "enter-globe",
      text: dateFormats.monthAndYear(content?.event_date),
    };
  }
  return undefined;
}

function IconCreate(content: any): BuildIcon | undefined {
  if (content?.created_at) {
    return {
      type: "event",
      text: "Created " + dateFormats.monthYearComma(content.created_at),
    };
  }
  return undefined;
}
function IconUpdate(content: any): BuildIcon | undefined {
  if (content?.updated_at) {
    return {
      type: "event",
      text: "Updated " + dateFormats.monthYearComma(content.updated_at),
    };
  }
  return undefined;
}

function IconCertificationLevel(content: any): BuildIcon | undefined {
  if (content?.certification_level?.[0]) {
    let iconType: string = "";
    switch (content.certification_level[0]) {
      case "Associate":
        iconType = "associate";
        break;
      case "Expert":
        iconType = "expert";
        break;
      case "Professional":
        iconType = "professional";
        break;
      default:
        iconType = "novice";
    }
    return {
      type: iconType,
      text: content.certification_level[0],
    };
  }
  return undefined;
}

function IconCalendarRange(content: any): BuildIcon | undefined {
  if (!content) return undefined;
  const globalProduct = content?.global_products?.find(
    (product: any) => product?.is_single === 1
  );
  if (globalProduct?.ga_date && globalProduct?.eos_date) {
    return MergeDate(globalProduct?.ga_date, globalProduct?.eos_date);
  } else if (content?.ga_date && content?.eos_date) {
    return MergeDate(content?.ga_date, content?.eos_date);
  }
  return undefined;
}

function MergeDate(startDate: any, endDate: any): any {
  const startYear = new Date(startDate).getFullYear();
  const endYear = new Date(endDate).getFullYear();

  if (startYear === endYear) {
    return {
      type: "event",
      text:
        dateFormats.longMonthDayNoYearFmtUtc(startDate) +
        " to " +
        dateFormats.longMonthDayNoYearFmtUtc(endDate) +
        ", " +
        startYear,
    };
  } else {
    return {
      type: "event",
      text:
        dateFormats.longMonthFmtUtc(startDate) +
        " to " +
        dateFormats.longMonthFmtUtc(endDate),
    };
  }
}

function IconRetire(content: any): BuildIcon | undefined {
  if (!content) return undefined;
  const globalProduct = content?.global_products?.find(
    (product: any) => product?.is_single === 1
  );
  if (globalProduct?.eos_date || content?.eos_date) {
    return {
      type: "event",
      text:
        "Retiring " +
        dateFormats.longMonthFmtUtc(
          globalProduct?.eos_date ? globalProduct?.eos_date : content?.eos_date
        ),
    };
  }
  return undefined;
}

function returnType(cType: any) {
  let rtn = "";
  const path = "learning-path";
  const videoSeries = "video-series";
  const ilt = "instructor-led";
  const practiceExam = "practice-exam";
  switch (cType) {
    case "path":
      rtn = path;
      break;
    case "webinar":
      rtn = "virtual-event";
      break;
    case "videoSeries":
    case "track":
    case videoSeries:
    case "video-track":
      rtn = videoSeries;
      break;
    case "video":
      rtn = "video";
      break;
    case "podcast":
      rtn = "podcast";
      break;
    case "course":
      rtn = "course";
      break;
    case "challenge":
      rtn = "challenge";
      break;
    case "tutorial":
      rtn = "tutorial";
      break;
    case ilt:
      rtn = ilt;
      break;
    case practiceExam:
      rtn = practiceExam;
      break;
    default:
      rtn = path;
  }
  return rtn;
}

function retiringContent(content: any): boolean {
  if (!content) return false;
  const globalProduct = content?.global_products?.find(
    (product: any) => product?.is_single === 1
  );
  if (!globalProduct?.eos_date && !content?.eos_date) return false;
  const expiration_date = new Date(
    globalProduct?.eos_date ? globalProduct?.eos_date : content?.eos_date
  );
  expiration_date.setDate(expiration_date.getDate() - 30);
  return new Date() >= expiration_date;
}

function prioritizeDate(content: any): BuildIcon | undefined {
  const retireIcon = IconRetire(content);
  if (retireIcon) {
    return retireIcon;
  }

  const updateIcon = IconUpdate(content);
  if (updateIcon) {
    return updateIcon;
  }

  return IconCreate(content);
}

export function buildGarden(content: any, iconLimit: number): Array<any> {
  let rtn: Array<any> = [];
  const calendar = IconCalendar(content);
  const difficulty = IconDifficultyType(content);
  const time = IconDuration(content);
  const courses = IconCourses(content);
  const labs = IconLabs(content);
  const credits = IconCreds(content);
  const assessments = IconAssessments(content);
  const display_version = IconVersion(content);
  const create = IconCreate(content);
  const certificationLevel = IconCertificationLevel(content);
  const videos = IconVideos(content);
  const dateRange = IconCalendarRange(content);
  const retireIcon = IconRetire(content);
  const dateIcon = prioritizeDate(content);

  switch (returnType(content?.type)) {
    case "podcast":
      rtn.push(time, calendar);
      break;
    case "virtual-event":
      rtn.push(time);
      break;
    case "learning-path":
      rtn.push(
        difficulty,
        time,
        labs,
        assessments,
        display_version,
        dateIcon,
        credits
      );
      break;
    case "instructor-led":
      rtn.push(difficulty, time);
      rtn = rtn.filter((ele) => ele); // remove undefined values
      if (rtn.length < iconLimit) rtn.push(courses);
      if (rtn.length < iconLimit) rtn.push(labs);
      if (rtn.length < iconLimit) rtn.push(assessments);
      rtn.push(display_version, create, credits);
      break;
    case "course":
      rtn.push(difficulty, time, labs, dateIcon);
      break;
    case "challenge":
      rtn.push(difficulty, time);
      if (!retiringContent(content)) {
        rtn.push(dateRange);
      } else {
        rtn.push(retireIcon);
      }
      break;
    case "practice-exam":
      rtn.push(certificationLevel, dateIcon);
      break;
    case "tutorial":
      rtn.push(difficulty, time, dateIcon);
      break;
    case "video-track":
    case "video-series":
    case "video":
      rtn.push(difficulty, time, dateIcon, videos);
      break;
  }
  rtn = rtn.filter((ele) => ele); // remove undefined values
  return rtn;
}

export default buildGarden;
