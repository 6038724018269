<script lang="ts">
import "@cisco-u/icons/icons/cu-icon-chevron-right.js";

import { defineComponent } from "vue";
import { mapActions, mapGetters, mapMutations } from "vuex";

import ScrolledTelem from "@/components/ScrolledTelem.vue";
import { Element } from "@/components/Survey";
import { fetch } from "@/services/middlewareService/communities";
import { fetchOnboardingSurveyResponse } from "@/services/profileService/surveys";
import { EventBus } from "@/store/eventBus";
import handleLearningCatalog from "@/utils/handleLearningCatalog";
import isUnauth from "@/utils/isUnauth";
import { Community } from "@/views/CommunityLanding/Community";
import communityResult from "@/views/CommunityLanding/communityResult.vue";
import discussionResult from "@/views/CommunityLanding/discussionResult.vue";
import myList from "@/views/ForYou/myList.vue";
import RecommendCarousel from "@/views/ForYou/RecommendCarousel.vue";

const allTopics: string = "All Topics";
const unlisted: string = "My interests are not listed here.";

export default defineComponent({
  props: {
    boxShowable: {
      type: Boolean,
      default: false,
    },
    onboardSurvey: {
      type: Object,
      default: null,
    },
    getSurveyAnswers: {
      type: Object,
      default: null,
    },
  },

  components: {
    myList,
    communityResult,
    discussionResult,
    ScrolledTelem,
    RecommendCarousel,
  },

  data() {
    return {
      userTopics: [],
      communities: [] as Community[],
      certificationCommunities: [] as Community[],
      associateCommunities: [] as Community[],
      moreCommunities: [] as Community[],
      communitytop: [] as any,
      communitybottom: [] as any,
      topics: [] as string[],
      learning_options: [] as string[],
      selected: 0,
      vssWidth: 0,
      fetched: false,
    };
  },

  computed: {
    isUnauth,
    ...mapGetters({
      myNewLearningList: "user/myNewLearningList",
      filledLearningList: "user/filledLearningList",
      status: "user/status",
      myList: "user/myListResults",
      achievements: "user/achievements",
    }),
    topicsString(): string {
      if (
        !this.getSurveyAnswers ||
        this.getSurveyAnswers.questions.length === 0
      )
        return "";
      return this.getSurveyAnswers.questions[0].answers
        .map((element: Element) => {
          return element.value;
        })
        .join(", ");
    },
    newAccount(): boolean {
      const surveyNotCompleted = Boolean(
        this.status && !this.status.surveyCompleted
      );
      const emptyList = !this.myList?.length;
      return surveyNotCompleted && emptyList;
    },
    userOnboardingTutorial() {
      const optOut: Array<string> =
        this.achievements?.tutorials?.["Opt-out"]?.map(
          (item: any) => item.GUID
        ) || [];
      return [
        "7ab1d787-7fda-4559-92fd-27912ee1ff92",
        "05d39d27-9c10-4ef4-a3cc-7f5b0b2572c7",
        "398895dc-51bc-4b73-bc6d-d0a10c3b53b5",
      ].filter((item) => !optOut.includes(item));
    },
    showChallenge() {
      return window.env.ENABLE_CTF;
    },
  },

  async mounted() {
    if (this.myNewLearningList != null) {
      const missingData = await handleLearningCatalog(this.myNewLearningList);
      this.setFilledLearningList(missingData);
    }

    window.addEventListener("scroll", this.handleScroll);
    this.vssWidth = window.screen.availWidth;
    window.onresize = () => {
      this.vssWidth = window.screen.availWidth;
    };
    EventBus.on("syncTopics", () => {
      this.getSurveyResponse();
    });
    EventBus.on("updateUserProgress", () => {
      this.fetchProgress();
    });
    this.getSurveyResponse();
  },

  unmounted() {
    window.removeEventListener("scroll", this.handleScroll);
  },

  watch: {
    async myNewLearningList() {
      const missingData = await handleLearningCatalog(this.myNewLearningList);
      this.setFilledLearningList(missingData);
    },
  },

  methods: {
    ...mapActions({
      fetchProgress: "user/fetchProgress",
    }),
    ...mapMutations({
      setSurveyAnswers: "user/setSurveyAnswers",
      setFilledLearningList: "user/setFilledLearningList",
    }),
    handleLearningCatalog,
    handleScroll() {
      if (window.scrollY > 100 && !this.fetched) {
        this.fetchData();
        this.fetched = true;
      }
    },

    fetchData() {
      fetch()
        .then((data) => {
          this.communities = data.communities;
          this.certificationCommunities = data.certificationCommunities;
          this.associateCommunities = data.associateCommunities;
          this.moreCommunities = data.moreCommunities;
          this.communitytop = data.communitytop;
          this.communitybottom = data.communitybottom;
          this.fetched = true;
        })
        .catch(() => {
          // do nothing
        });
    },

    getSurveyResponse() {
      if (!this.isUnauth) {
        fetchOnboardingSurveyResponse()
          .then((data: any) => {
            this.topics = [];
            if (data) {
              this.setSurveyAnswers(data);
              this.userTopics = data.questions[0].answers.map(
                (element: Element) => {
                  return element.value;
                }
              );
              if (
                data.questions[0].answers[0].value === "" &&
                this.topics.length === 1
              ) {
                this.topics = this.learning_options;
                this.selected = 0;
              }
            } else {
              // Removing unnecessary option
              this.topics = this.learning_options.filter(
                (e: string) => e !== unlisted
              );
              this.selected = 0;
            }

            // Add the default option
            this.topics.push(allTopics);
          })
          .catch(() => {
            // intentional
          });
      }
    },
  },
});
</script>

<template>
  <div ref="container" class="bg-white-lightest dark:bg-black-lightest">
    <div class="pb-8">
      <div class="border-white-dark">
        <scrolled-telem :threshold="100" telem-send="My List">
          <my-list :my-list="filledLearningList" :box-showable="boxShowable" />
        </scrolled-telem>

        <RecommendCarousel
          :topics-string="topicsString"
          content-type="tutorial"
          :userOnboardingTutorial="userOnboardingTutorial"
          :status="status"
        />

        <RecommendCarousel :topics-string="topicsString" content-type="path" />

        <RecommendCarousel
          :topics-string="topicsString"
          content-type="course"
        />

        <RecommendCarousel
          v-if="showChallenge"
          :topics-string="topicsString"
          content-type="challenge"
        />

        <RecommendCarousel
          :topics-string="topicsString"
          content-type="practice-exam"
        />

        <RecommendCarousel
          :topics-string="topicsString"
          content-type="instructor-led"
        />

        <div
          class="mx-4 mt-10 md:mx-6 xxl:mx-20 xxl:mt-20 xlg:mx-auto xlg:max-w-[100rem]"
        >
          <h2
            class="mb-4 text-29 font-light text-black-dark dark:text-white-dark"
          >
            Most Popular
            <div class="text-3xl font-bold md:inline-block">Communities</div>
            <div v-if="vssWidth >= 768">
              <router-link
                to="/communities"
                class="absolute right-0 float-right text-16 text-blue-light hover:text-blue sm:-mt-10 md:relative md:inline-block"
              >
                View all Communities and Discussions
                <cu-icon-chevron-right
                  class="text-black md:relative md:bottom-0 md:inline-block"
                ></cu-icon-chevron-right>
              </router-link>
            </div>
          </h2>
          <div class="mb-16 flex-row border-b pt-4 lg:flex">
            <community-result
              v-for="community in communitytop"
              :key="community.Name"
              :community="community.Name"
              :link="community.URL"
              :followers="community.Followers"
              :posts="community.Posts"
              :articles="community.Articles"
              :certification="vssWidth >= 640"
            />
            <discussion-result
              v-for="community in communitytop"
              :key="community['Name']"
              :id="community['Id']"
              :numposts="1"
              :hideBtn="false"
            />
          </div>
          <div>
            <div class="flex-row pt-2.5 lg:flex">
              <community-result
                v-for="community in communitybottom"
                :key="community.Name"
                :community="community.Name"
                :link="community.URL"
                :followers="community.Followers"
                :posts="community.Posts"
                :articles="community.Articles"
                :certification="false"
              />
              <discussion-result
                v-for="community in communitybottom"
                :key="community['Name']"
                :id="community['Id']"
                :numposts="1"
                :hideBtn="false"
              />
            </div>
            <div v-if="vssWidth < 768">
              <router-link
                to="/communities"
                class="flex items-center justify-end text-14 text-blue-light hover:text-blue"
              >
                View all Communities and Discussions

                <cu-icon-chevron-right
                  class="text-black md:relative md:bottom-0 md:inline-block"
                ></cu-icon-chevron-right>
              </router-link>
            </div>
          </div>
        </div>

        <RecommendCarousel
          :topics-string="topicsString"
          content-type="podcast"
        />

        <RecommendCarousel
          :topics-string="topicsString"
          content-type="webinar"
        />

        <RecommendCarousel :topics-string="topicsString" content-type="video" />
      </div>
    </div>
  </div>
</template>
